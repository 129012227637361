<template>
  <VCard class="step-lecture">
    <header class="step__header">
      <StepToolbar :step="step" />

      <VCardTitle class="lesson-title">
        {{ step.title }}
      </VCardTitle>

      <LessonMeeting :meeting="step.meeting" />

      <VTabs
        v-model="activeTabName"
        class="mt-8"
        @change="changeTabs"
      >
        <VTab
          v-for="(tab, name) in tabs"
          :key="name"
          :href="`#${name}`"
        >
          <VBadge
            :value="name !== 'contents' ? tab.size : 0"
            :content="name !== 'contents' ? tab.size : 0"
            inline
            bordered
          >
            <VIcon left>
              {{ tab.icon }}
            </VIcon>
            {{ $t(tab.title) }}
          </VBadge>
        </VTab>
      </VTabs>
      <VDivider />
    </header>

    <div :class="['step__body', {'no-user-select': vendorCopyProtectionEnabled, 'slides': activeTabName === 'slides' }]">
      <UPaginator
        v-if="activeTab.paginator && activeTab.size > 1"
        ref="pagination"
        v-model="activePage"
        classes="mt-4"
        :length="activeTab.size"
        :total-visible="7"
        @input="changePage"
      />

      <VTabsItems
        ref="container"
        v-model="activeTabName"
        class="flex-grow-1"
      >
        <VTabItem
          v-for="(tab, name) in tabs"
          :key="name"
          :value="name"
        >
          <VWindow
            v-if="name === 'contents'"
            :value="activePage"
            :touchless="true"
          >
            <VWindowItem
              v-if="isPublicDescription"
              key="description"
              :value="1"
            >
              <VCard
                flat
                class="lecture-page"
              >
                <VCardTitle class="lecture-page__title display-3">
                  {{ step.title }}
                </VCardTitle>
                <VCardText>
                  <div
                    class="lecture-page__body rte-content bvi-speech"
                    v-html="step.description"
                  />
                </VCardText>
              </VCard>
            </VWindowItem>

            <VWindowItem
              v-for="(content, idx) in lecture.contents"
              :key="content.id"
              :value="idx + (isPublicDescription ? 2 : 1)"
            >
              <VCard
                flat
                class="lecture-page"
              >
                <VCardTitle
                  v-if="!(content.settings || {}).hide_title"
                  class="lecture-page__title display-3"
                >
                  {{ content.title }}
                </VCardTitle>
                <VCardText>
                  <div
                    class="lecture-page__body rte-content bvi-speech"
                    v-html="content.body"
                  />
                </VCardText>
              </VCard>
            </VWindowItem>
          </VWindow>

          <VWindow
            v-if="name === 'slides'"
            :value="activePage"
            :touchless="true"
          >
            <VWindowItem
              v-for="(slide, idx) in lecture.slides"
              :key="slide.id"
              :value="idx + 1"
            >
              <VCard flat>
                <VCardText class="text--primary">
                  <img
                    v-if="slide.type.code === 'file'"
                    :src="slide.body"
                    :alt="slide.title"
                    class="slide-img"
                  >
                  <div
                    v-else
                    class="lecture-page rte-content"
                    v-html="slide.body"
                  />
                </VCardText>
              </VCard>
            </VWindowItem>
          </VWindow>

          <VCardText v-if="name === 'videos'">
            <VRow>
              <VCol
                v-for="video in lecture.videos"
                :key="video.id"
                cols="12"
                sm="6"
                lg="4"
              >
                <VCard>
                  <UVideo
                    :type-code="video.type.code"
                    :body="video.body"
                  />
                  <VListItem>
                    <VListItemContent>
                      <VListItemTitle :title="video.title">
                        {{ video.title }}
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>

          <UDocuments
            v-if="name === 'documents'"
            :documents="lecture.documents"
          />
        </VTabItem>
      </VTabsItems>

      <UPaginator
        v-if="activeTab.paginator && activeTab.size > 1"
        v-model="activePage"
        classes="my-4"
        :length="activeTab.size"
        :total-visible="7"
        @input="changePage"
      />
    </div>

    <VDialog
      v-model="showModal"
      max-width="500px"
    >
      <VCard>
        <VCardTitle>В прошлый раз вы остановились на странице {{ savedPage }}, хотите продолжить с неё?</VCardTitle>
        <VDivider />
        <VCardActions>
          <VSpacer />
          <VBtn
            color="primary"
            @click="continueFromSavedPage"
          >
            {{ $t('yes.one') }}
          </VBtn>
          <VBtn
            text
            color="error"
            @click="discardSavedPage"
          >
            {{ $t('no.one') }}
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </VCard>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { UDate } from '@/utils/date'

import UPaginator from '@components/UPaginator.vue'
import UVideo from '@components/UVideo.vue'
import UDocuments from '@components/UDocuments.vue'
import StepToolbar from '@components/StepToolbar.vue'
import LessonMeeting from '@components/LessonMeeting.vue'
import FullScreen from '@components/FullScreen.vue'
import * as getters from '@/store/getters/types'

export default {
  name: 'TrainingLecture',
  components: {
    UVideo,
    UDocuments,
    UPaginator,
    StepToolbar,
    LessonMeeting
  },

  data () {
    return {
      observers: [],
      activeTabName: '',
      activePage: 1,
      showModal: false,
      firstTabVisit: {},
      tabs: {
        contents: {
          title: 'lectures.one',
          icon: 'notes',
          paginator: true,
          page: 1,
          size: 0
        },
        slides: {
          title: 'slides.other',
          icon: 'slideshow',
          paginator: true,
          page: 1,
          size: 0
        },
        videos: {
          title: 'videos.other',
          icon: 'slideshow',
          paginator: false,
          page: 1,
          size: 0
        },
        documents: {
          title: 'documents.other',
          icon: 'format_list_bulleted',
          paginator: false,
          page: 1,
          size: 0
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
      session: getters.SESSION,
      step: getters.STEP,
      lecture: getters.LECTURE
    }),

    vendorCopyProtectionEnabled () {
      return this.vendor?.branding?.copy_protection_enabled || false
    },

    activeTab () {
      return this.tabs[this.activeTabName] || {}
    },

    isPublicDescription () {
      return !!this.step?.settings?.is_public_description
    },

    savedPage () {
      const page = localStorage.getItem(`lecture-page-${this.lecture.id}-${this.activeTabName}`)
      return page ? Number(page) : null
    }
  },

  created () {
    this.register()
  },

  methods: {
    turnOffMedia (element) {
      if (element.tagName === 'IFRAME') {
        if (element.hidden) {
          element.hidden = false
        }

        const src = element.src
        const isVimeoVideo = /\/\/(www\.|player\.)?vimeo\.com\//.test(src)
        const isYouTubeVideo = /\/\/(www\.)?youtu(be\.com|\.be)\//.test(src)

        if (isVimeoVideo || isYouTubeVideo) {
          element.src = src
          return
        }

        return
      }

      element.pause()
    },

    changePage (page) {
      this.saveCurrentPage(page)

      const query = Object.assign({}, this.$route.query, {
        page
      })

      if (this.activeTab.paginator) {
        this.activeTab.page = page
        this.$router.replace({ query }).catch(() => {})
      } else {
        delete query.page
        this.$router.replace({ query }).catch(() => {})
      }

      const container = this.$refs.container.$el
      container.querySelectorAll('audio, video, iframe').forEach(element => {
        this.turnOffMedia(element)
      })

      if (screen.width < 600 && this.activeTabName === 'slides' && this.$refs.pagination) {
        const y = this.$refs.pagination.$el.offsetTop
        if (JSON.parse(localStorage.getItem('isFullView'))) {
          this.$nextTick(() => document.querySelector('.v-dialog--fullscreen').scrollTo(0, y))
        } else {
          this.$nextTick(() => scrollTo(0, y))
        }
      }
    },

    saveCurrentPage (page) {
      if (this.activeTab.size === page || page === 1) {
        this.removeSavedPage()
      } else {
        this.setSavedPage(page)
      }
    },

    continueFromSavedPage () {
      this.activePage = Number(this.savedPage)
      this.changePage(this.savedPage)
      this.showModal = false
    },

    discardSavedPage () {
      this.removeSavedPage()
      this.showModal = false
    },

    setSavedPage (page) {
      localStorage.setItem(`lecture-page-${this.lecture.id}-${this.activeTabName}`, page)
    },

    removeSavedPage () {
      localStorage.removeItem(`lecture-page-${this.lecture.id}-${this.activeTabName}`)
    },

    changeTabs (tab) {
      const query = Object.assign({}, this.$route.query, {
        tab
      })

      this.$router.replace({ query }).catch(() => {})

      this.activeTabName = tab
      this.activePage = this.activeTab.page

      // Если нет значения 'savedPage' или оно не валидно, то просто сменим вкладку
      if (this.savedPage === null || this.savedPage > this.activeTab.size) {
        this.changePage(this.activePage)
        this.firstTabVisit[tab] = true
        return
      }

      // Если данный таб посещяем впервые, то покажем модальное окно
      if (!this.firstTabVisit[tab]) {
        this.showModal = true
        this.firstTabVisit[tab] = true
        return
      }

      // Если данный таб посещали, переключим страницу самостоятельно
      this.activePage = Number(this.savedPage)
      this.changePage(this.savedPage)
    },

    register () {
      this.lecture.documents = this.prepareDocuments(this.lecture.documents)

      Object.keys(this.tabs).forEach(name => {
        this.tabs[name].size = this.lecture[name].length

        if (name === 'contents' && this.isPublicDescription) {
          this.tabs[name].size++
        }

        if (!this.tabs[name].size) {
          delete this.tabs[name]
        }
      })

      const query = this.$route.query
      this.activeTabName = query.tab || ''

      const size = this.activeTab.size || 1
      const page = parseInt((query.page || 1), 10)
      this.activePage = Math.min(Math.max(1, page), size)

      if (this.savedPage !== null) {
        this.showModal = true
      }

      // eslint-disable-next-line no-extra-semi
      ;(new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            Array.from(mutation.addedNodes || []).forEach((node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.matches('object[type="application/pdf"], iframe')) {
                  this.initFullscreen(node)
                } else {
                  node.querySelectorAll('object[type="application/pdf"]:not(.target__wrapper object), iframe').forEach((innerNode) => {
                    const isPDF = innerNode.matches('object[type="application/pdf"]')
                    const parentElement = isPDF ? innerNode : innerNode.closest('.fixed-aspect')

                    if (parentElement && !parentElement.closest('.target__wrapper')) {
                      this.initFullscreen(parentElement)
                    }
                  })
                }
              }
            })
          }
        })
      })).observe(document.body, {
        childList: true,
        subtree: true,
      })
    },

    initFullscreen (node) {
      const Wrapper = Vue.extend(FullScreen)
      const backupСontent = node.innerHTML
      const _wrapper = new Wrapper({
        propsData: {
          html: node.outerHTML,
          backupСontent,
        }
      })

      _wrapper.$mount()
      node.parentNode.replaceChild(_wrapper.$el, node)
    },

    prepareDocuments (documents) {
      return [...documents.reduce((map, document) => {
        if (!map.has(document.uri)) {
          map.set(document.uri, document)
        }

        return map
      }, new Map()).values()]
    },

    formatDateTime: UDate.formatDateTime
  }
}
</script>

<style lang="scss">
.lecture-page {
  max-width: 60rem;
  margin: 0 auto;
}

.lecture-page__title {
  padding-top: 1.25em;
  padding-bottom: 0.75em;
}

.lecture-page__body {
  line-height: 1.5;
  font-size: 1rem;
  color: map-deep-get($material-light, "text", "primary");
}

.slide-img {
  display: block;
  margin: 0 auto;
  max-height: 500px;
}

.slides {
  min-height: 95vh;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    min-height: unset;
  }
}
</style>
